import React from "react"
import { graphql } from "gatsby"
import styled from "styled-components"
import Layout from "../components/layout"

const PostContainer = styled.div`
  max-width: 750px;
  margin: 0 auto;
  min-height: calc(100vh - 400px);
  padding: 30px 30px 100px 30px;

  p {
    line-height: 22px;
  }

  div img[alt="Steve Fischer"] {
    float: right;
    width: 200px;
    height: auto;
    margin: 0px 0px 20px 30px;
    border: 1px solid #dae0e6;
    border-radius: 3px;
  }

  @media only screen and (max-width: 700px) {
    div img[alt="Steve Fischer"] {
      width: 150px;
    }
  }
`

function BlogPost(props) {
  const post = props.data.markdownRemark
  return (
    <Layout>
      <PostContainer>
        <h1>{post.frontmatter.title}</h1>
        <div dangerouslySetInnerHTML={{ __html: post.html }} />
      </PostContainer>
    </Layout>
  )
}

export const query = graphql`
  query($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
      }
    }
  }
`

export default BlogPost
